import LayoutOne from "layouts/LayoutOne";
import React, { useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { useSearchParams } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const Pages = React.forwardRef((props, ref) => {
  return (
    <div className="demoPage" ref={ref}>
      <p>{props.children}</p>
    </div>
  );
});

function ComicBook() {
  const [numPages, setNumPages] = useState(null);

  const [activePageNumber, setActivePageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const comicBookRef = useRef();

  const [params] = useSearchParams();

  const imageCover = params?.get("img")
    ? decodeURIComponent(params?.get("img"))
    : "";

  return (
    <LayoutOne>
      {/* {loading && <Loading loading = {loading} />} */}
      <div className="mt-12 flex flex-col justify-end items-center md:justify-center scroll-mx-2 overflow-hidden">
        <HTMLFlipBook
          width={500}
          height={500}
          showPageCorners
          maxShadowOpacity={0.5}
          mobileScrollSupport={true}
          ref={comicBookRef}
          onFlip={(e) => {
            setActivePageNumber(
              comicBookRef?.current?.pageFlip()?.getCurrentPageIndex()
            );
          }}
        >
          <div className="w-[500px] h-[400px] mt-1">
            <img
              src={`${imageCover ?? "./demo.png"}`}
              alt="Demo Cover"
              className="w-[500px] h-[400px] object-cover"
            />
          </div>

          {[...Array(numPages)?.keys()].map((n) => (
            <Pages number={`${n + 2}`}>
              <Document
                file={"./comic.pdf"}
                onLoadSuccess={onDocumentLoadSuccess}
                onError={(err) => console.log(err)}
              >
                <Page
                  pageNumber={n + 2}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  width={500}
                  height={500}
                  className="border-3 border-black"
                />
              </Document>
            </Pages>
          ))}
        </HTMLFlipBook>

        <div className="flex items-center justify-center gap-5 -mt-10 pb-16">
          <button
            className="btn btn-primary"
            onClick={() => comicBookRef?.current?.pageFlip().flipPrev()}
          >
            Previous
          </button>
          [<span class="page-current">{activePageNumber}</span> of{" "}
          <span class="page-total">
            {comicBookRef?.current?.pageFlip()?.getPageCount()}
          </span>
          ]
          <button
            className="btn btn-primary"
            onClick={() => comicBookRef?.current?.pageFlip().flipNext()}
          >
            Next
          </button>
        </div>
      </div>
    </LayoutOne>
  );
}

export default ComicBook;
