import React, { useState, useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import ContactOne from "../../components/Contact/ContactOne";
import Roadmap from "../../components/Roadmap/Roadmap";
import Sales from "../../components/Sales/Sales";
import TeamOne from "../../components/Team/TeamOne";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
// import { loadStripeOnramp } from "@stripe/crypto";

const Home = () => {
  // const [value, setValue ] = useState('10')
  // const [url, setUrl] = useState()

  // useEffect(() => {
  //   const standaloneOnramp = window?.StripeOnramp?.Standalone({
  //     source_currency: 'usd',
  //     amount: {source_amount: value},
  //   });
  //   const redirectUrl = standaloneOnramp.getUrl();
  //   setUrl(redirectUrl)
  // }, [value])
  return (
    <LayoutOne>
      <main className="fix">
        
        <Banner />
        <Sales />
        <WhoWeAre />
        <WhyChooseUs />
        
        {/* <input onChange={(e) => setValue(parseFloat(e.target.value))}/>
        <a href={url}>TEST {url}</a> */}

        <div className="area-bg">
          <Roadmap />
          <WhitePaper />
        </div>

        <TeamOne />
        <ContactOne />
      </main>
    </LayoutOne>
  );
};

export default Home;
